import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import ContactFormCareers from "../components/blocks/ContactFormCareers";
import JobListing from "../components/blocks/JobListing";

import { constructActiveDoc } from "../utilities";

const Careers = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const {
    title,
    image,
    image_mobile: imageMobile,
    header,
    header_positions: headerPositions,
    description,
    positions,
  } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          image={image}
          imageMobile={imageMobile}
        />
        <Container className="text-center text-lg-start">
          <Row className="my-5">
            <Col lg="4">
              <div className="decorated-header">
                <PrismicRichText field={header.richText} />
              </div>
            </Col>
            <Col lg="8" className="pt-5">
              <div className="basic-text">
                <PrismicRichText field={description.richText} />
              </div>
              <ContactFormCareers className="my-5" settings={settings} />
            </Col>
          </Row>
          <Row className="my-5">
            <Col lg="4">
              <div className="decorated-header">
                <PrismicRichText field={headerPositions.richText} />
              </div>
            </Col>
            <Col lg="8" className="pt-5">
              {positions.map((position) => (
                <JobListing
                  position={position}
                  key={position.position_description}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query careersQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicCareers(lang: { eq: $lang }) {
      ...prismicCareersFragment
    }
  }
`;

export default Careers;
