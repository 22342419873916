import React from "react";
import { Button } from "react-bootstrap";

import Anchor from "../elements/Anchor";

const JobListing = ({ position }) => {
  const {
    position_title: title,
    position_description: desc,
    position_link: link,
    position_link_label: linkLabel,
  } = position;

  return (
    <article className="mb-5">
      <h3 className="h4 mb-0 fw-bold">{title}</h3>
      <p className="basic-text">{desc}</p>
      <Button
        as={Anchor}
        variant="dark"
        href={link.url}
        target={link.target}
        className="px-4"
      >
        {linkLabel}
      </Button>
    </article>
  );
};

export default JobListing;
